// Import all plugins
import * as bootstrap from 'bootstrap';
import * as AOS from 'aos';

AOS.init();

$(document).ready(function () {

  $("nav").find("a").click(function(e) {
    e.preventDefault();
    var section = $(this).attr("href");
    $("html, body").animate({
        scrollTop: $(section).offset().top
    });
  });

  $('.slider').slick({
    centerMode: true,
    adaptiveHeight: false,
    slidesToShow: 3,
    infinite: false,
    dots: true,
    arrows: true,
    asNavFor: '.slider-nav',
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  $('.slider-nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider',
    dots: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: true
  });


$('.nav-link').on('click',function(e){
  $( ".nav-item .nav-link" ).each( function() {
    $( this ).removeClass( "active" );
  });
  $(this).addClass("active"); //add the class to the clicked element
  e.stopPropagation();
});
$('.navbar-brand').on('click',function(e){
  $( ".nav-item .nav-link" ).each( function() {
    $( this ).removeClass( "active" );
  });
  e.stopPropagation();
});

});

